.map {
  background-color: $white;

  &__container {
    position: relative;
    height: calc(100vh - 60px);
    width: 100%;

    @media (max-width: $md - 1px) {
      height: calc(100vh - 30px);
    }

    #map {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      .gm-style .gm-ui-hover-effect {
        display: none !important;
      }

      .gm-style .gm-style-iw-t {
        &::before, &::after {
          display: none;
        }
      }

      .gm-style .gm-style-iw {
        width: 160px !important;
        overflow: visible !important;
        background-color: transparent;
        padding: 0;
        transform: translate(-50%, -100%);
        box-shadow: none;

        & > div {
          background-color: $white;
          max-width: none !important;
          max-height: none !important;
          display: block !important;
          overflow: visible !important;
          width: 160px;
          animation: grow 0.3s $easing;
          transform-origin: bottom center;
          box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);

          & > div {
            overflow: visible !important;
          }
        }
      }
    }
  }

  &__tooltip {
    display: flex;
    flex-wrap: wrap;
    width: 160px;
    padding: 8px 18px 10px;
    color: $brown;
    font-family: $font;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      left: calc(50% - 5px);
      bottom: -5px;
      transform: rotate(45deg);
      transform-origin: center center;
      pointer-events: none;
      background-color: $white;
    }

    > p {
      width: 100%;
      text-align: center;
    }
  }

  &__infos {
    display: flex;
    justify-content: space-between;
    margin: 0 -5px -5px;
    width: calc(100% + 10px);
  }

  &__info {
    margin: 5px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      @include bgContain;
    }

    p {
      font-size: 11px;
      line-height: 16px;
    }

    &--drive {
      padding-left: 25px;

      &::before {
        width: 18px;
        background-image: url(../img/icon_car.svg);
      }
    }

    &--distance {
      padding-left: 19px;

      &::before {
        width: 12px;
        background-image: url(../img/icon_pin_l.svg);
      }
    }
  }

  &__controls {
    position: absolute;
    bottom: 90px;
    right: 30px;

    @media (max-width: $md - 1px) {
      bottom: 60px;
      right: 15px;
    }
  }

  &__control {
    width: 42px;
    height: 42px;
    @include bgContain;
    cursor: pointer;

    &:not(:first-child) {
      margin-top: 10px;
    }

    &--zoomin {
      background-image: url(../img/icon_zoomin.svg);
    }

    &--zoomout {
      background-image: url(../img/icon_zoomout.svg);
    }
  }

  &__filters {
    background-color: $brown;
    height: 100px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: calc(100% - 40px);
    width: 100%;

    @media (max-width: $md - 1px) {
      height: 70px;
    }
  }

  &__filtersform {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }

  &__filter {
    padding: 0 20px;
    min-width: 120px;

    @media (max-width: $md - 1px) {
      padding: 0;
      min-width: 60px;
    }

    input {
      display: none;

      &:checked + label {
        opacity: 1;

        &::after {
          width: 20px;
          opacity: 1;
        }

        &::before {
          transform: scale(1);
          opacity: 1;
        }
      }
    }

    label {
      position: relative;
      cursor: pointer;
      text-align: center;
      opacity: 0.3;
      transition: 0.2s $easing;

      @media (min-width: $md) {
        &:hover {
          opacity: 1;
        }
      }

      img {
        display: inline-block;
        width: 32px;
        height: 32px;

        @media (max-width: $md - 1px) {
          width: 24px;
          height: 24px;
        }
      }

      p {
        color: $dsand;
        margin-top: 10px;
        font-size: 13px;
        line-height: 21px;

        @media (max-width: $md - 1px) {
          font-size: 9px;
          line-height: 15px;
          margin-top: 5px;
        }
      }

      &::after, &::before {
        content: '';
        position: absolute;
        top: -10px;
        left: 0;
        width: 20px;
        height: 20px;
        transition: 0.2s $easing;
      }

      &::before {
        background-color: $green;
        border-radius: 50%;
        transform-origin: center center;
        opacity: 0;
        transform: scale(0.5);
      }

      &::after {
        background-image: url(../img/check.svg);
        background-size: 10px 7px;
        background-position: left 5px center;
        background-repeat: no-repeat;
        width: 0;
        opacity: 0;
      }
    }
  }
}
