#bankoffers {
  .modal__outer {
    padding: 15px 0;
  }

  .modal__inner {
    position: relative;

    .modal__close {
      margin-top: 0;
      top: 15px;
      right: 15px;
    }
  }

  .container {
    @media (max-width: $md - 1px) {
      padding: 0 15px;
    }
  }
}

.bankoffers {
  max-width: 540px;
  margin: 0 auto;

  &__inner {
    padding: 15px 20px 20px;


    @media (max-width: $md - 1px) {
      padding: 20px;
    }
  }

  &__heading {
    margin-bottom: 20px;
  }

  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -8px;

    @media (max-width: $md - 1px) {
      justify-content: center;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &__item {
    margin: 8px;
    width: calc(#{percentage(1/3)} - 16px);
    @include bgContain;

    @media (max-width: $md - 1px) {
      width: 150px;
    }

    &::after {
      border: 1px solid $sand;
      content: '';
      display: block;
      width: 100%;
      padding-bottom: percentage(2/3);
      transition: 0.15s $easing;
    }

    &:hover {
      &::after {
        border-color: $brown;
      }
    }
  }
}
