.floors {
  height: calc(100vh - 20px);
  color: $white;
  background: $brown;
  position: relative;

  &--home {
    height: calc(100vh - 75px);

    .floors__btn {
      bottom: 70px;

      @media (max-width: $md - 1px) {
        bottom: 30px;
      }
    }
  }

  @media (max-width: $md - 1px) {
    max-height: calc(100vh - 75px);
    height: auto;
    overflow: hidden;

    .floors {
      &__visuals, &__switcher {
        display: none;
      }
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 125%;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url('../img/stogas_vertical.jpg');
      @include bgCover;
    }
  }

  @media (max-width: 376px) {
    max-height: none;
    height: 470px;

    &::after {
      display: none;
    }
  }

  &__btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 220px;
    z-index: 3;

    @media (max-width: $md - 1px) {
      bottom: 30px;
    }
  }

  &__switcher {
    position: absolute;
    right: 70px;
    bottom: 50px;
    z-index: 3;

    p, label {
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: right;
    }

    p {
      letter-spacing: 0.08em;
      margin-bottom: 10px;
    }

    label {
      cursor: pointer;
      padding: 5px 0;
      position: relative;

      &:hover {
        opacity: 0.8;
      }

      &::before {
        content: '';
        position: absolute;
        top: calc(50% - 1px);
        right: 30px;
        height: 2px;
        background-color: currentColor;
        opacity: 0;
        width: 0;
        transition: 0.3s $easing;
      }
    }

    input:checked + label::before {
      opacity: 1;
      width: 20px;
    }
  }

  &__visuals {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    svg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 1;
      pointer-events: auto;
      transition: left 0.001ms linear, opacity 0.3s $easing 0.001ms;
      z-index: 2;

      &:not(.active) {
        z-index: 1;
        opacity: 0;
        pointer-events: none;
        left: 100%;
        transition: opacity 0.001ms $easing (0.001ms + 0.3s), left 0.001ms $easing (0.001ms + 0.3s);
      }

      &[data-floor="roof"] {
        cursor: pointer;
      }

      path {
        opacity: 0.6;
        fill: $green;

        &:hover {
          opacity: 0.8;
        }

        &.not, &.sold {
          fill: $dbrown;
        }

        &[data-flr] {
          cursor: pointer;
          opacity: 0;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
