body {
  background-color: $white;
  color: $brown;
  font-family: $font;
  font-weight: 400;
  font-size: $basepx;

  &.fixed {
    height: 100%;
    overflow: hidden;
  }
}

.container {
  max-width: $lg;
  width: 100%;
  padding: 0 35px;
  margin: 0 auto;

  @media (min-width: $md) {
    padding: 0 50px;
  }

  .wrap {
    padding: 0 7.8125%;

    @media (max-width: $md - 1px) {
      padding: 0;
    }
  }
}
