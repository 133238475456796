.textphotos {
  margin: 80px 0;

  @media (max-width: $md - 1px) {
    margin: 0;
    overflow: hidden;
  }

  &__item {
    margin: 60px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $md - 1px) {
      display: block;
    }

    &:nth-child(even) {
      .textphotos__text {
        order: 1;
      }
      .textphotos__photo {
        order: 2;
      }
    }
  }

  &__text, &__photo {
    width: calc(50% - 8px);
  }

  &__photo {
    @media (max-width: $md - 1px) {
      width: calc(100% + 35px);
      margin-bottom: 15px;
    }
  }

  &__text {
    padding: 0 8%;

    @media (max-width: $md - 1px) {
      width: 100%;
      padding: 0;
    }

    h3 {
      margin-bottom: 15px;
    }
  }

  &__image {
    padding-bottom: 100%;
    @include bgCover;
  }
}
