.filters {
  text-align: center;

  &__wrap {
    &--closed {
      .filters__form {
        display: none;
      }
    }
  }

  &__free {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__checkbox {
    input {
      display: none;

      &:checked + label::after {
        opacity: 0.6;
        transform: scale(1);
      }
    }

    label {
      font-weight: 700;
      font-size: 11px;
      line-height: 20px;
      padding-left: 30px;
      position: relative;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border: 2px solid currentColor;
        border-radius: 4px;
      }

      &::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 2px;
        left: 6px;
        top: 6px;
        background-color: currentColor;
        opacity: 0;
        transition: transform 0.15s $easing, opacity 0.15s $easing;
        transform-origin: center center;
        transform: scale(0.5);
      }
    }
  }

  &__form {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 0 25px;
    margin: 0 auto;
  }

  h5, p, & {
    font-size: 11px;
  }

  &__close {
    display: inline-block;
    text-align: center;
    font-size: 13px;
    font-weight: 700;
    padding: 15px 40px 15px 20px;
    cursor: pointer;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      right: 20px;
      top: 50%;
      border-bottom: 2px solid currentColor;
      border-right: 2px solid currentColor;
    }

    &::before {
      content: attr(data-open);
    }

    &::after {
      transform: translateY(-25%) rotate(-135deg);
    }

    &--closed {
      &::before {
        content: attr(data-closed);
      }

      &::after {
        transform: translateY(-75%) rotate(45deg);
      }
    }
  }

  &__col {
    width: 25%;
    padding: 25px 35px;
    text-align: center;

    @media (max-width: $md - 1px) {
      width: 50%;
    }
    @media (max-width: $sm - 1px) {
      width: 100%;
    }
  }

  &__rangewrap {
    position: relative;

    .min, .max {
      position: absolute;
      top: 25px;
      opacity: 0.6;
    }

    .min {
      left: 35px;
      transform: translateX(-50%);
    }

    .max {
      right: 35px;
      transform: translateX(50%);
    }

    > h5 {
      margin-bottom: 10px;
    }

    input {
      display: none;
    }

    .noUi-horizontal {
      height: 2px;
      height: 2px;
      border-radius: 0;
      border: 0;
      box-shadow: none;
      background-color: rgba($brown, 0.2);

      .noUi-connects {
        border-radius: 0;

        .noUi-connect {
          background: $brown;
        }
      }

      .noUi-tooltip {
        line-height: 20px;
        top: 16px;
        color: $brown;
        background: rgba($white, 0.7);
        border: 0;
        bottom: auto;
      }

      .noUi-handle {
        width: 16px;
        height: 16px;
        right: -8px !important;
        border-radius: 50%;
        border: 0;
        background-color: $brown;
        box-shadow: none;

        &::before, &::after {
          display: none;
        }
      }
    }
  }
}
