.table {
  color: $brown;

  &, &__row {
    border-top: 1px solid rgba($brown, 0.15);
    border-bottom: 1px solid rgba($brown, 0.15);
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    padding: 10px 0;
  }

  &__col {
    max-width: 50%;
    font-size: 13px;
    line-height: 15px;
    opacity: 0.6;
    padding: 0 20px;

    @media (max-width: $md - 1px) {
      padding: 0;
    }

    &--head {
      opacity: 1;
      font-weight: 700;
    }
  }
}
