.list {
  &--freeonly {
    .list__table .list__item--sold {
      display: none !important;
    }
  }

  &--offers {
    .tabs {
      &__tab label {
        border-bottom: 0;
        border-top: 2px solid;
      }
    }
  }

  &--loading {
    &:not(.list--loaded) {
      & > * {
        display: none;
      }

      &::before {
        content: '';
        display: block;
        width: 100px;
        height: 100px;
        margin: 0 auto;
        background-image: url(../img/loader_d.svg);
        @include bgSize(40px, 40px);
      }
    }
  }

  &__head {
    background-color: $brown;
    color: $white;

    .list__row {
      height: 40px;
    }

    .list__col {
      h5 {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  &__item {
    display: none;
    color: rgba($brown, 0.6);
    border: 0 1px solid rgba($brown, 0.15);

    &--sold {
      color: rgba($brown, 0.3);
    }

    &--show {
      display: block;
    }

    &:hover {
      background-color: rgba($sand, 0.5);
      border-color: rgba($sand, 0.5);

      .list__col--offer {
        background-color: #C7432F;
      }
    }

    .list__row {
      min-height: 50px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__col {
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
    padding: 10px 0;

    p {
      font-size: 13px;
      line-height: 20px;
    }

    &--offer {
      background-color: $red;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      p {
        width: 50%;
        font-size: 11px;
        line-height: 18px;
        opacity: 0.6;

        &.wide {
          width: 100%;
          font-weight: 700;
          font-size: 13px;
          line-height: 20px;
          opacity: 1;
        }

        &.old {
          text-decoration: line-through;
        }
      }

    }
  }

  .filters {
    ~ .list__scroller {
      &::before {
        top: 0;
      }
    }

    ~ .list__tables {
      @media (max-width: $md - 1px) {
        padding-top: 44px;
      }
    }
  }

  &__scroller {
    position: relative;
    display: none;

    @media (max-width: $md - 1px) {
      display: block;
    }

    &::before {
      content: '';
      position: absolute;
      left: calc(50% - 26px);
      top: 10px;
      display: block;
      width: 52px;
      height: 34px;
      background-image: url(../img/hand.svg);
      @include bgContain;
    }
  }

  &__tables {
    overflow: auto;
    width: 100%;
    position: relative;

    @media (max-width: $md - 1px) {
      padding-top: 54px;
    }

    .ps__rail {
      &-x, &-y {
        display: none;
      }
    }
  }

  &__table {
    display: block;
    min-width: 700px;

    .wrap {
      max-width: none !important;
    }
  }
}
