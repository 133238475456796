.tooltip {
  display: flex;

  > div {
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.16);
  }

  &__apartment {
    background-color: $white;
    width: 180px;
    color: $brown;
    position: relative;

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      left: calc(50% - 5px);
      transform-origin: center center;
      transform: rotate(45deg);
      background-color: $white;
    }

    &--sold {
      .tooltip__top {
        background-color: $brown;
        color: $white;
      }
    }
  }

  &__notsold {
    background-color: $white;
    width: 140px;
    padding: 10px 15px;
    color: $brown;
    position: relative;
    text-align: center;

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      left: calc(50% - 5px);
      transform-origin: center center;
      transform: rotate(45deg);
      background-color: $white;
    }

    p {
      font-size: 11px;
      line-height: 14px;
      font-weight: 700;
    }

    .tooltip__icon {
      width: 20px;
      height: 18px;
      display: inline-block;
      background-image: url(../img/icon_apartment.svg);
      @include bgContain;
    }
  }

  &__all {
    background-color: $white;
    width: 70px;
    padding: 10px 15px;
    color: $brown;
    position: relative;
    text-align: center;

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      left: calc(50% - 5px);
      transform-origin: center center;
      transform: rotate(45deg);
      background-color: $white;
    }

    h4 {
      font-weight: 700;
    }

    p {
      font-size: 11px;
      line-height: 14px;
      font-weight: 700;
    }

    .tooltip__icon {
      width: 20px;
      height: 18px;
      display: inline-block;
      background-image: url(../img/icon_apartment.svg);
      @include bgContain;
    }
  }

  &__table {
    padding: 5px 10px;

    &--sand {
      background-color: $sand;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0.01em;
      max-width: 40%;
      padding: 5px;

      &.th {
        font-weight: 700;
        max-width: 60%;
      }
    }
  }

  &__top {
    background-color: $sand;
    color: $brown;
    padding: 12px 15px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h4 {
      font-weight: 700;
      padding-right: 7px;
      border-right: 2px solid currentColor;
      margin-right: 7px;
      line-height: 20px;
    }

    p {
      font-size: 11px;
      line-height: 17px;
      letter-spacing: 0.01em;
      font-weight: 700;
    }
  }

  &__free {
    background-color: $white;
    color: $brown;
    text-align: center;
    width: 70px;
    padding: 20px 10px 10px;
    position: relative;

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      left: calc(50% - 5px);
      transform-origin: center center;
      transform: rotate(45deg);
      background-color: $white;
    }

    img {
      width: 20px;
      height: 18px;
      display: inline-block;
    }

    h4 {
      font-weight: 700;
    }

    p {
      font-size: 11px;
      line-height: 15px;
    }
  }
}

.tippy-popper {
  .tippy-tooltip {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    overflow: visible;
  }

  .tippy-backdrop {
    background: transparent !important;
  }

  .tooltip > .tooltip__apartment.tooltip__apartment--sold {
    &::before {
      background-color: $brown;
    }
  }

  &[x-placement*="top"] {
    .tooltip > div {
      margin-bottom: 7px;

      &::before {
        bottom: -5px;
        top: auto;
      }
    }
  }

  &[x-placement*="bottom"] {
    .tooltip > div {
      margin-top: 7px;

      &::before {
        top: -5px;
        bottom: auto;
      }
    }

    .tooltip > .tooltip__apartment:not(.tooltip__apartment--sold) {
      &::before {
        background-color: $sand;
      }
    }
  }
}
