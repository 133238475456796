@charset "utf-8";
@import "../../node_modules/tippy.js/dist/tippy.css";
@import "../../node_modules/swiper/dist/css/swiper.min.css";
@import "../../node_modules/nouislider/distribute/nouislider.min.css";
@import "../../node_modules/perfect-scrollbar/css/perfect-scrollbar.css";

@import "helpers/mixins";
@import "helpers/reset";
@import "helpers/fonts";
@import "helpers/variables";
@import "helpers/typography";
@import "helpers/typography";
@import "helpers/animations";

@import "parts/common";
@import "parts/btn";
@import "parts/floatingbtns";
@import "parts/header";
@import "parts/menu";
@import "parts/hero";
@import "parts/playbutton";
@import "parts/modal";
@import "parts/about";
@import "parts/heading";
@import "parts/contact";
@import "parts/form";
@import "parts/map";
@import "parts/abouthead";
@import "parts/gallery";
@import "parts/textphotos";
@import "parts/textblocks";
@import "parts/textpage";
@import "parts/news";
@import "parts/404";
@import "parts/cookie";
@import "parts/developer";
@import "parts/floors";
@import "parts/filters";
@import "parts/list";
@import "parts/apartment";
@import "parts/table";
@import "parts/bankoffers";
@import "parts/calc";
@import "parts/switches";
@import "parts/tooltip";
