.btn {
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.01em;
  padding: 14px;
  width: 100%;
  max-width: 220px;
  background-color: $sand;
  color: $brown;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  cursor: pointer;

  &::after {
    content: '';
    background-image: url(../img/hover_arr_dark.svg);
    background-size: 28px 10px;
    background-position: right center;
    background-repeat: no-repeat;
    height: 20px;
    opacity: 0;
    transform: scaleX(0.5);
    width: 0;
    transition: 0.3s $easing;
    pointer-events: none;
    position: relative;
    z-index: 1;
  }

  &:hover {
    &::after {
      width: 39px;
      opacity: 1;
      transform: scaleX(1);
    }
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &--loading {
    .btn__loader {
      pointer-events: auto;
      opacity: 1;
    }
  }

  &__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $brown;
    top: 0;
    left: 0;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: default;
    pointer-events: none;
    opacity: 0;
    transition: 0.3s $easing;

    img {
      width: 25px;
      height: 25px;
    }
  }

  &--dark {
    background-color: $brown;
    color: $white;

    &::after {
      background-image: url(../img/hover_arr_light.svg);
    }
  }

  &--chev-down {
    &::before {
      content: '';
      background-color: $sand;
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: -5px;
      left: calc(50% - 5px);
      transform-origin: center center;
      transform: rotate(45deg);
    }
  }

  &--offer {
    width: 200px;
    background-color: $green;
    padding-left: 48px;
    left: 0;
    overflow: hidden;
    transition: 0.3s $easing;
    position: relative;

    &::after {
      display: none;
    }

    &::before {
      content: '';
      position: absolute;
      pointer-events: none;
      left: 0;
      top: 0;
      width: 48px;
      height: 48px;
      background-image: url(../img/tag.svg);
      @include bgSize(24px, 24px);
    }

    &:not(:hover) {
      left: 152px;
    }
  }
}

.arrowbtn {
  padding: 15px 0;
  height: 40px;
  width: 60px;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    top: 15px;
    position: absolute;
    transition: 0.3s $easing;
    width: 60px;
    height: 10px;
    background-size: 60px 10px;
    background-repeat: no-repeat;
    left: 0;
    background-image: url(../img/arrow_next.svg);
    background-position: right center;
    transform: translate3d(-20px, 0, 0);
  }

  &--back {
    &::before {
      background-image: url(../img/arrow_prev.svg);
      right: 0;
      transform: translate3d(20px, 0, 0);
    }
  }

  &:hover {
    &::before {
      transform: translate3d(0, 0, 0);
    }
  }

  &--light {
    &::before {
      background-image: url(../img/arrow_next_l.svg);
    }

    &.arrowbtn--back {
      &::before {
        background-image: url(../img/arrow_prev_l.svg);
      }
    }
  }
}
