.page404 {
  display: flex;
  flex-direction: row;
  position: relative;
  @include bgCover;
  color: $white;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($brown, 0.6);
  }

  &__textwrap {
    width: 100%;
    min-height: 100vh;
    padding: 135px 35px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    h4 {
      max-width: 420px;
    }

    .big {
      font-size: 200px;
      line-height: 1;
      opacity: 0.1;
      margin-bottom: -30px;

      @media (max-width: $md - 1px) {
        font-size: 146px;
      }
    }

    .btn {
      margin-top: 40px;
    }
  }

  &__copyright {
    position: absolute;
    bottom: 30px;
    text-align: center;
    width: 100%;
    padding: 0 35px;
    color: rgba($white, 0.5);
    z-index: 2;

    a {
      color: $white;
      opacity: 1;

      &:hover {
        color: rgba($white, 0.5);
      }
    }
  }
}
