.switches {
  &.hide {
    display: none;
  }

  &__switch {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    input {
      display: none;

      &:checked ~ label::after {
        transform: translateX(45px);
      }
    }

    small {
      font-size: 13px;
      line-height: 20px;
      font-weight: 700;
    }

    label {
      position: relative;
      width: 74px;
      height: 24px;
      padding: 0 8px;
      color: $brown;
      cursor: pointer;

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background-color: currentColor;
        display: block;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 3px;
        width: 18px;
        height: 18px;
        background-color: $white;
        border-radius: 50%;
        transition: transform 0.15s $easing;
        transform: translateX(11px);
      }
    }
  }
}
