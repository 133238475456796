.textpage {
  margin: 80px 0;
  overflow: hidden;

  @media (max-width: $md - 1px) {
    margin: 40px 0;
  }

  &__content {
    width: 75%;

    @media (max-width: $md - 1px) {
      width: 100%;
    }

    .news__date {
      margin-bottom: 45px;

      @media (max-width: $md - 1px) {
        margin-bottom: 30px;
      }
    }

    table {
      border-collapse: collapse;
      border: 1px solid $brown;

      th, td {
        border: 1px solid $brown;
        padding: 10px;
      }

      th {
        font-weight: 700;
      }
    }
  }

  .gallery {
    overflow: visible;
    margin: 80px 0;

    @media (max-width: $md - 1px) {
      margin: 30px 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  p, ul li {
    color: rgba($brown, 0.6);

    a, strong {
      color: $brown;
      opacity: 1;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  ul li::before {
    background-color: $brown;
    top: 11px;
    left: 5px;
  }

  h1, h2, h3, h4, h5, h6, p, ul, ol {
    margin: 1em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
