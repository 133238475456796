#calculator {
  .modal__outer {
    padding: 15px 0;
  }

  .modal__inner {
    position: relative;

    .modal__close {
      margin-top: 0;
      top: 15px;
      right: 15px;
    }
  }

  .container {
    @media (max-width: $md - 1px) {
      padding: 0 15px;
    }
  }
}

.calc {
  max-width: 840px;
  margin: 0 auto;

  &__inner {
    padding: 0 10%;
    overflow: hidden;
  }

  &__heading {
    padding: 40px 0 15px;

    @media (max-width: $md - 1px) {
      padding: 20px 35px 15px 0;
    }

    p {
      max-width: 480px;
      opacity: 0.6;
      font-size: 15px;
      line-height: 25px;
      letter-spacing: 0.01em;
      margin-top: 0.5em;

      @media (max-width: $md - 1px) {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  &__inputgroup {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    @media (max-width: $md - 1px) {
      padding-top: 20px;
    }

    &:not(:first-child) {
      margin-top: 30px;
    }

    input {
      width: 100%;
      font-size: 15px;
      line-height: 25px;
      height: 47px;
      border: 0;
      font-family: $font;
      color: rgba($brown, 0.6);
      border-bottom: 2px solid rgba($brown, 0.2);
      transition: 0.2s $easing;

      @media (max-width: $md - 1px) {
        font-size: 13px;
        height: 42px;
      }
    }
  }

  &__form {
    padding: 15px 0 50px;
  }

  &__results {
    position: relative;
    padding: 24px 0 30px;

    &::before {
      content: '';
      z-index: 0;
      left: calc(50% - 50vw);
      width: 100vw;
      height: 100%;
      top: 0;
      position: absolute;
      background-color: $sand;
    }

    > * {
      position: relative;
      z-index: 1;
    }

    p {
      opacity: 0.6;

      &.big {
        opacity: 1;
        font-size: 15px;
        line-height: 25px;
        margin-bottom: 1em;

        @media (max-width: $md - 1px) {
          font-size: 13px;
          line-height: 20px;
        }
      }
    }
  }

  &__input {
    width: 58%;

    @media (max-width: $md - 1px) {
      width: calc(50% - 5px);
    }

    h5 {
      font-size: 15px;
      line-height: 25px;
      font-family: $font;
      color: $brown;
      font-weight: 400;

      @media (max-width: $md - 1px) {
        font-size: 13px;
        line-height: 20px;
      }
    }

    &--range {
      @media (max-width: $md - 1px) {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }

      h5 {
        @media (min-width: $md) {
          margin-top: 10px;
        }
      }

      .calc__range {
        margin-top: 10px;

        @media (max-width: $md - 1px) {
          display: none;
        }
      }

      .noUi-horizontal {
        height: 2px;
        height: 2px;
        border-radius: 0;
        border: 0;
        box-shadow: none;
        background-color: rgba($brown, 0.2);

        .noUi-connects {
          border-radius: 0;

          .noUi-connect {
            background: $brown;
          }
        }

        .noUi-handle {
          width: 16px;
          height: 16px;
          right: -8px !important;
          border-radius: 50%;
          border: 0;
          background-color: $brown;
          box-shadow: none;

          &::before, &::after {
            display: none;
          }
        }
      }
    }

    &--text {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & + {
        .calc__output {
          @media (max-width: $md - 1px) {
            width: calc(50% - 5px);
          }
        }
      }

      h5 {
        white-space: nowrap;
        border-right: 2px solid rgba($brown, 0.2);
        border-bottom: 2px solid rgba($brown, 0.2);
        padding-right: 20px;
        line-height: 45px;
        transition: 0.2s $easing;

        @media (max-width: $md - 1px) {
          border: 0;
          padding: 0;
          transition: none;
          top: 0;
          position: absolute;
          line-height: 20px;
        }
      }

      input {
        padding-left: 20px;

        @media (max-width: $md - 1px) {
          padding: 0;
        }
      }

      &.calc__input--focus {
        h5, input {
          border-color: $brown;
        }

        input {
          color: $brown;
        }
      }
    }
  }

  &__output {
    width: 36%;
    position: relative;

    @media (max-width: $md - 1px) {
      width: 100%;
    }

    &::before {
      content: attr(data-postfix);
      font-size: 15px;
      line-height: 45px;
      position: absolute;
      right: 0;
      top: 0;

      @media (max-width: $md - 1px) {
        font-size: 13px;
        line-height: 40px;
      }
    }

    input {
      &:focus {
        color: rgba($brown, 1);
        border-bottom: 2px solid rgba($brown, 1);
      }
    }
  }
}
