.developer {
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  &__intro {
    z-index: 1;
    position: relative;
    min-height: calc(100vh - 80px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__bgslides {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: $brown;

    &::before {
      z-index: 2;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba($brown, 0.6);
    }

    > div {
      height: 100%;

      > div {
        height: 100%;

        > div {
          height: 100%;
        }
      }
    }
  }

  &__bgslide {
    @include bgCover;
  }

  &__texts {
    z-index: 2;
    position: relative;
    color: $white;
    padding: 80px 30px;
    max-width: 500px;
    width: 100%;
    text-align: center;

    @media (max-width: $md - 1px) {
      padding: 80px 30px 40px;
    }

    > img {
      width: 82px;
      height: 42px;
      display: inline-block;
      opacity: 0.6;
      margin-bottom: 50px;

      @media (max-width: $md - 1px) {
        margin-bottom: 30px;
      }
    }

    > h2 {
      margin-bottom: 30px;

      @media (max-width: $md - 1px) {
        margin-bottom: 20px;
      }
    }

    > .btn {
      margin-top: 65px;

      @media (max-width: $md - 1px) {
        margin-top: 25px;
      }
    }

    p {
      color: rgba($white, 0.6);

      a {
        color: $white;
        opacity: 1;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    padding: 0 20px;

    .swiper-button {
      &-prev, &-next {
        right: auto;
        left: auto;
        top: auto;
        bottom: auto;
        position: relative;
        padding: 15px 0;
        height: 40px;
        width: 60px;
        margin: 0;
        background: transparent;
        overflow: hidden;

        &::before {
          content: '';
          top: 15px;
          position: absolute;
          transition: 0.3s $easing;
          width: 60px;
          height: 10px;
          background-size: 60px 10px;
          background-repeat: no-repeat;
        }

        &:hover {
          &::before {
            transform: translate3d(0, 0, 0);
          }
        }
      }

      &-prev {
        left: -20px;

        &::before {
          right: 0;
          background-image: url(../img/arrow_prev_l.svg);
          background-position: left center;
          transform: translate3d(20px, 0, 0);
        }
      }

      &-next {
        right: -20px;

        &::before {
          left: 0;
          background-image: url(../img/arrow_next_l.svg);
          background-position: right center;
          transform: translate3d(-20px, 0, 0);
        }
      }
    }

    .swiper-pagination {
      position: static;
      display: flex;
      align-items: center;
      justify-content: center;

      &-bullet {
        width: 18px;
        height: 18px;
        position: relative;
        background-color: transparent;
        cursor: pointer;
        transition: 0.2s $easing;

        &::before {
          content: '';
          position: absolute;
          width: 4px;
          height: 4px;
          top: 7px;
          left: 7px;
          opacity: 0.6;
          border-radius: 50%;
          pointer-events: none;
          background-color: $white;
          transition: 0.2s $easing;
        }

        &-active {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  &__certificates {
    position: absolute;
    height: 50px;
    width: 370px;
    right: 50px;
    top: 40px;
    @include bgContain;
    background-image: url(../img/certificates.png);

    @media (max-width: $md - 1px) {
      width: 246px;
      height: 33px;
      right: auto;
      left: calc(50% - 123px);
      top: 30px;
    }
  }

  &__projects {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 3;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s $easing;
    transform: translateX(100vw);

    &--exist {
      transform: translateX(0);
    }

    &--open {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__back {
    position: absolute;
    left: 40px;
    top: 35px;
    z-index: 2;
    display: inline-flex;
    color: $white;
    align-items: center;
    cursor: pointer;

    p {
      margin-left: 20px;
    }

    &:hover {
      .arrowbtn::before {
        transform: translate3d(0, 0, 0);
      }
    }

    @media (max-width: $md - 1px) {
      left: 5px;
      top: 20px;
    }
  }

  &__projectslides {
    .developer__nav {
      position: absolute;
      height: auto;
      bottom: 105px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 5;
    }

    > div {
      height: 100%;

      > div {
        height: 100%;

        > div {
          height: 100%;
        }
      }
    }
  }

  &__projectslides, &__projectslist {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
    transform: translateX(100vw);
  }

  @media (max-width: $md - 1px) {
    &__projectslides {
      transform: translateX(0);
      opacity: 1;
      pointer-events: auto;
    }
  }

  @media (min-width: $md) {
    &__projectslist {
      transform: translateX(0);
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__projectslist {
    display: flex;
    transform: translate3d(0, 0, 0);
  }

  &__project {
    width: 25%;
    flex-shrink: 0;
    height: 100%;
    @include bgCover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $lg - 1px) and (min-width: $md) {
      width: percentage(1/3);
    }

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.6;
      background-color: $brown;
      pointer-events: none;
      transition: 0.2s $easing;
    }

    img {
      position: relative;
      z-index: 2;
      width: 130px;
    }

    .btn {
      position: absolute;
      z-index: 2;
      bottom: 80px;
      width: calc(100% - 60px);
      left: 50%;
      opacity: 0;
      pointer-events: none;
      transition: 0.2s $easing;
      transform-origin: center bottom;
      transform: translate(-50%, 50px);
    }

    &:hover, &--slide {
      .btn {
        opacity: 1;
        pointer-events: auto;
        transform: translate(-50%, 0);
      }

      &::before {
        opacity: 0.4;
      }
    }

    &--slide {
      width: 100%;

      .btn {
        bottom: 40px;
      }
    }
  }
}
