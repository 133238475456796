.textblocks {
  padding: 50px 0;
  background-color: $sand;

  @media (max-width: $md - 1px) {
    padding: 20px 0;
  }

  &__frame, &__item {
    border: 1px solid $brown;
  }

  &__frame {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: $md - 1px) {
      margin: 0 -15px;
    }
  }

  &__item {
    width: 50%;
    padding: 30px 60px 40px 30px;

    @media (max-width: $md - 1px) {
      width: 100%;
      padding: 30px 20px 30px 30px;
    }

    &:nth-child(odd):last-child {
      width: 100%;
    }

    img {
      width: 60px;
      height: 50px;
      margin-bottom: 30px;

      @media (max-width: $md - 1px) {
        margin-bottom: 20px;
      }
    }

    h3 {
      margin-bottom: 10px;
    }
  }
}
