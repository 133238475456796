.apartment {
  padding: 140px 0 70px;

  @media (max-width: $md - 1px) {
    padding: 110px 0 30px;
  }

  .container {
    .wrap {
      display: flex;
      justify-content: space-between;
      position: relative;

      @media (max-width: $md - 1px) {
        display: block;
      }

      &::before {
        content: '';
        pointer-events: none;
        position: absolute;
        top: 0;
        left: calc(50% - 1px);
        height: 100%;
        width: 2px;
        background-color: $brown;

        @media (max-width: $md - 1px) {
          display: none;
        }
      }
    }
  }

  &__heading  {
    h1, h2 {
      width: 100%;
      margin-bottom: 40px;

      @media (max-width: $md - 1px) {
        margin-bottom: 20px;
      }
    }
  }

  &__visuals {
    width: 50%;
    position: relative;
    min-height: 600px;

    @media (max-width: $md - 1px) {
      width: 100%;
      padding-bottom: 150%;
      margin-top: 20px;
      min-height: 0;
    }

    .switches {
      position: absolute;
      left: 16%;
      top: 10px;

      @media (max-width: $md - 1px) {
        left: 0;
      }
    }

    .hero__back {
      position: absolute;
      right: -5%;
      top: 0;

      @media (max-width: $md - 1px) {
        display: none;
      }
    }
  }

  &__visual {
    position: absolute;
    left: 5%;
    width: 115%;
    height: calc(100% - 70px);
    top: 70px;
    display: none;
    @include bgContain;

    @media (max-width: $md - 1px) {
      left: 0;
      width: 100%;
    }

    &--show {
      display: block;
    }
  }

  &__table {
    width: 40%;

    @media (max-width: $md - 1px) {
      width: 100%;
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 20px -10px;

    @media (max-width: $md - 1px) {
      display: none;
    }

    &--mobile {
      display: none;

      @media (max-width: $md - 1px) {
        display: flex;
      }
    }

    .btn {
      width: calc(50% - 20px);
      margin: 10px;
      height: 48px;
      line-height: 15px;

      @media (max-width: $md - 1px) {
        width: calc(100% - 20px);
      }
    }

    .link {
      margin: 10px;
      font-size: 13px;
      font-weight: 700;
      line-height: 20px;
      border-bottom: 2px solid currentColor;
      cursor: pointer;
      transition: 0.3s $easing;

      &:hover {
        border-color: transparent;
      }
    }
  }
}
