.cookie {
  position: fixed;
  left: 20px;
  bottom: 20px;
  width: calc(100% - 40px);
  max-width: 320px;
  padding: 20px;
  z-index: 9;
  background-color: $brown;
  color: $white;
  text-align: center;
  opacity: 1;
  transform: scale(1);
  transform-origin: left bottom;
  transition: 0.3s $easing;

  &--hide {
    transform: scale(0.5);
    opacity: 0;
  }

  p {
    color: rgba($white, 0.6);
    font-size: 11px;
    line-height: 15px;

    a {
      color: $white;
      opacity: 1;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .btn {
    margin-top: 20px;
  }
}
