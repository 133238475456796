.contact {
  padding: 70px 0 30px;
  background-color: $sand;

  @media (max-width: $md - 1px) {
    padding: 50px 0 30px;
  }

  &--page {
    padding-top: 125px;
    min-height: 100vh;
  }

  &__frame {
    border: 2px solid $brown;

    @media (min-width: $md) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__people, &__form {
    width: 100%;

    @media (min-width: $md) {
      width: 50%;
    }
  }

  &__people {
    border-bottom: 1px solid $brown;
    padding: 40px;

    @media (max-width: $md - 1px) {
      padding: 30px 20px;
    }

    @media (min-width: $md) {
      border-bottom: 0;
      border-right: 1px solid $brown;
    }
  }

  &__form {
    border-top: 1px solid $brown;
    padding: 40px 20px 30px;

    @media (min-width: $md) {
      border-top: 0;
      border-left: 1px solid $brown;
      padding: 50px 40px 40px;
    }
  }

  &__person {
    width: 100%;

    &:not(:first-child) {
      margin-top: 30px;
    }

    img {
      margin-bottom: 10px;
      width: 100%;

      @media (min-width: $sm) {
        width: 40%;
        margin-bottom: 0;
      }
    }

    @media (min-width: $sm) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:first-child) {
        margin-top: 40px;
      }
    }
  }

  &__contacts {
    h4 {
      margin-bottom: 5px;
    }

    @media (min-width: $sm) {
      width: calc(60% - 30px);
    }
  }

  &__location {
    width: 100%;
    border-top: 2px solid $brown;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 40px 50px;

    @media (max-width: $md - 1px) {
      padding: 40px 20px 50px;
    }
  }

  &__address {
    margin-bottom: 20px;
    line-height: 24px;
    color: rgba($brown, 0.6);
    position: relative;
    padding-left: 28px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 24px;
      background-image: url(../img/icon_pin.svg);
      @include bgContain;
    }
  }

  &__copyright {
    margin-top: 30px;
    margin-bottom: 10px;
    color: rgba($brown, 0.6);

    @media (min-width: $md) {
      margin-top: 40px;
    }

    @media (max-width: $md - 1px) {
      width: 100%;
      text-align: center;
    }

    a {
      opacity: 1;
      color: $brown;
      transition: 0.2s $easing;


      &:hover {
        color: rgba($brown, 0.6);
      }
    }
  }

  &__textblock {
    font-size: 15px;
    letter-spacing: 0.01em;
    line-height: 25px;
    color: rgba($brown, 0.6);
    margin-bottom: 35px;

    @media (max-width: $md - 1px) {
      font-size: 13px;
      line-height: 21px;
      margin-bottom: 40px;
    }
  }

  &__heading {
    h2 {
      margin-bottom: 34px;
      @media (max-width: $md - 1px) {
        margin-bottom: 30px;
      }
    }
  }
}
