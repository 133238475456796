.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateX(-100vw);
  pointer-events: none;
  transition: opacity 0.7s $easing, transform 0.001ms linear 0.7s;

  &--open {
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto;
    transition: opacity 0.7s $easing 0.001ms, transform 0.001ms linear;
  }

  .container {
    position: relative;
  }

  &__outer {
    width: 100%;
    max-height: 100%;
    overflow: auto;
    padding: 100px 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 200%;
    width: 100%;
    background-color: rgba($brown, 0.9);
  }

  &__closewrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    color: $white;
  }

  &__close {
    position: absolute;
    right: 35px;
    top: 0;
    width: 42px;
    height: 42px;
    padding: 12px 6px;
    margin-top: 24px;
    cursor: pointer;
    z-index: 3;

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 21px);
      left: calc(50% - 21px);
      width: 100%;
      height: 100%;
      border: 2px solid currentColor;
      border-radius: 50%;
      pointer-events: none;
      transform: scale(1);
      opacity: 1;
    }

    > div {
      width: 100%;
      height: 100%;
      position: relative;

      > div {
        position: absolute;
        background-color: currentColor;
        height: 2px;
        right: 6px;
        top: 8px;
        width: 18px;

        &:nth-child(1) {
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__inner {
    width: 100%;
    background-color: $white;
  }

  &--video {
    .modal__inner {
      background: transparent;
    }

    .modal__outer {
      padding: 0;
    }
  }

  &__video {
    position: relative;
    width: calc(100% + 70px);
    height: calc(100vh - 200px);
    left: -35px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $sm - 1px) {
      height: 100vh;
    }

    @media (max-height: 600px) {
      height: 100vh;
    }

    video {
      display: block;
      width: 0;
      height: 0;
    }
  }
}
