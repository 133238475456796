@include keyframes(rotation) {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@include keyframes(offset) {
  to {
    stroke-dashoffset: 0px;
  }
}

@include keyframes(fadein) {
  0% {
    opacity:0;
  }
  66% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@include keyframes(grow) {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.rotate {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: rotation;
}
