.abouthead {
  background-color: $sand;
  padding: 135px 0 70px;

  @media (max-width: $md - 1px) {
    padding: 120px 0 20px;
  }

  &__frame {
    border: 1px solid $brown;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: $md - 1px) {
      margin: 0 -15px
    }
  }

  &__col {
    width: 50%;
    border: 1px solid $brown;
    padding: 30px 30px 40px;

    @media (max-width: $md - 1px) {
      width: 100%;
    }
  }

  &__heading {
    width: 100%;
    padding: 30px;
    overflow: hidden;
    border: 1px solid $brown;
    position: relative;

    @media (max-width: $sm - 1px) {
      padding-bottom: 80px;
    }

    &::before {
      content: '';
      position: absolute;
      left: 75%;
      transform: translateX(-50%);
      bottom: -2px;
      width: 50%;
      height: 100%;
      max-height: 67px;
      max-width: 278px;
      @include bgContain;
      background-position: center bottom;
      background-image: url(../img/about_illi.svg);

      @media (max-width: $md - 1px) {
        width: 35%;
      }

      @media (max-width: $sm - 1px) {
        width: 200px;
        height: 66px;
        left: 50%;
        max-height: none;
        max-width: none;
      }
    }

    h1, h2 {
      margin: 0;
    }
  }
}
