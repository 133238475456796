.menu {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translate3d(100%, 0, 0);
  pointer-events: none;
  transition: 0.7s $easing;

  @media (min-width: $md) {
    position: static;
    transform: translate3d(0, 0, 0);
    pointer-events: auto;
    height: auto;
    width: auto;
    opacity: 1;
    color: $brown;
    transition: 0.3s $easing;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    background-color: $sand;
    width: 100%;
    height: 200%;
    left: 0;
    top: 0;
    transition: 0.3s $easing;

    @media (min-width: $md) {
      display: none;
    }
  }

  .svg {
    &:not(.svg--logo) {
      path {
        fill: currentColor;
      }
    }

    &--fb {
      width: 7px;
      height: 16px;
      display: block;
    }
  }

  &__wrap {
    padding-top: 60px;
    width: 100%;
    max-height: 100%;
    overflow: auto;

    @media (min-width: $md) {
      padding-top: 15px;
      max-height: none;
      width: auto;
      overflow: visible;
    }
  }

  &__list {
    @media (min-width: $md) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 0;
      max-height: none;
      width: auto;
    }
  }

  &__item {
    text-align: center;
    overflow: hidden;

    @media (max-width: $md - 1px) {
      &:not(:first-child) {
        margin-top: 15px;
      }
    }

    a {
      padding: 10px 15px;
      display: inline-block;
      font-size: 19px;
      line-height: 24px;

      @media (min-width: $md) {
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.01em;
        font-weight: 700;
      }
    }

    &:not(.menu__item--icon) {
      a {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          background-color: currentColor;
          bottom: 6px;
          height: 2px;
          width: calc(100% - 30px);
          left: 15px;
          transform-origin: center;
          transform: scaleX(0);
          transition: transform 0.3s $easing;
        }
      }

      &:hover, &.menu__item--active {
        a::before {
          transform: scaleX(1);
        }
      }
    }
  }
}

.header__toggler--open + .menu {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  pointer-events: auto;
  color: $brown !important;
}


