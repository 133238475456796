.floatingbtns {
  position: fixed;
  left: 100%;
  bottom: 40px;
  z-index: 8;

  @media (min-width: $md) {
    bottom: 100px;
  }

  .btn {
    transform: translate3d(-100%, 0, 0);

    &--dark {
      width: 155px;
    }

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  &--map {
    bottom: auto !important;
    top: calc(50% - 50px);
    transform: translateY(-50%);

    @media (max-height: 410px) {
      top: calc(50% - 65px);
    }

    .btn--dark {
      @media (max-height: 410px) {
        display: none;
      }

      @media (max-width: $sm - 1px) {
        width: 120px;
        transform: translate3d(-90%,0,0);

        &::after {
          display: none;
        }
      }
    }
  }
}
