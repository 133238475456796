$lg: 1280px;
$md: 992px;
$sm: 540px;
$basepx: 13px;

$font: 'Kometa', sans-serif;

$easing: ease-in-out;

$white:  #FFF;
$black:  #000;
$sand:   #F7EEE2;
$dsand:  #E0D5CE;
$brown:  #3F2C22;
$dbrown: #292929;
$green:  #DEE5CF;
$red:    #DF4B35;
