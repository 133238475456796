.about {
  margin: 50px 0 60px;
  overflow: hidden;

  @media (min-width: $md) {
    margin: 70px 0 80px;
  }

  &__swiper {
    left: -20px;
    width: calc(100% + 40px);
    margin: 0;
    overflow: visible;

    @media (min-width: $md) {
      left: auto;
      width: percentage(5/6);
    }
  }

  .swiper-slide {
    height: auto;
    display: flex;
  }

  &__slide {
    background-color: $sand;
    border: 20px solid $sand;
    position: relative;
    overflow: hidden;
    min-height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    @media (min-width: $md) {
      padding-top: 28.4%;
    }

    &:not(:hover) {
      .about__text {
        p {
          opacity: 0;
          pointer-events: none;
        }

        .about__icon {
          opacity: 0;
        }
      }
    }

    &:hover {
      .about__text {
        transform: translateY(0) !important;
      }
    }

    &::before {
      content: '';
      z-index: 2;
      pointer-events: none;
      position: absolute;
      border: 2px solid $brown;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 100px);
    @include bgCover;
    z-index: 0;
  }

  &__text {
    background-color: $sand;
    border-top: 2px solid $brown;
    transform: translateY(0);
    transition: 0.4s $easing;
    position: relative;
    z-index: 1;

    @media (min-width: $md) {
      display: flex;
    }

    h3 {
      margin-bottom: 20px;
    }

    p {
      opacity: 1;
      transition: 0.4s $easing;
    }
  }

  &__textinner {
    width: 100%;
    padding: 35px 20px 40px;

    @media (min-width: $md) {
      width: 75%;
      padding: 35px 20px 40px 40px;
    }
  }

  &__icon {
    display: none;

    @media (min-width: $md) {
      display: flex;
      align-items: flex-end;
      width: 25%;
      border-left: 2px solid $brown;
      transition: 0.4s $easing;
    }

    .icon {
      margin: 0 auto;
      width: 60%;
      height: 100%;
      @include bgContain;
      background-position: center bottom;
      transition: 0.4s $easing;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 0 20px;

    @media (min-width: $md) {
      position: absolute;
      right: -20%;
      top: -85px;
    }

    .swiper-button {
      &-prev, &-next {
        right: auto;
        left: auto;
        top: auto;
        bottom: auto;
        position: relative;
        padding: 15px 0;
        height: 40px;
        width: 60px;
        margin: 0;
        background: transparent;
        overflow: hidden;

        &::before {
          content: '';
          top: 15px;
          position: absolute;
          transition: 0.3s $easing;
          width: 60px;
          height: 10px;
          background-size: 60px 10px;
          background-repeat: no-repeat;
        }

        &:hover {
          &::before {
            transform: translate3d(0, 0, 0);
          }
        }
      }

      &-prev {
        left: -20px;

        &::before {
          right: 0;
          background-image: url(../img/arrow_prev.svg);
          background-position: left center;
          transform: translate3d(20px, 0, 0);
        }
      }

      &-next {
        right: -20px;

        &::before {
          left: 0;
          background-image: url(../img/arrow_next.svg);
          background-position: right center;
          transform: translate3d(-20px, 0, 0);
        }
      }
    }

    .swiper-pagination {
      position: static;
      display: flex;
      align-items: center;
      justify-content: center;

      &-bullet {
        width: 18px;
        height: 18px;
        position: relative;
        background-color: transparent;
        opacity: 1;
        cursor: pointer;
        transition: 0.2s $easing;

        &::before {
          content: '';
          position: absolute;
          background-color: $sand;
          width: 4px;
          height: 4px;
          top: 7px;
          left: 7px;
          border-radius: 50%;
          pointer-events: none;
          transition: 0.2s $easing;
        }

        &-active {
          &::before {
            background-color: $brown;
          }
        }
      }
    }
  }
}
