.heading {
  h2, h1 {
    margin: 0 0 25px;

    @media (min-width: $md) {
      width: 50%;
      margin: 0 0 50px;
    }
  }
}
