@font-face {
  font-family: 'Kometa';
  @include fontSrc('Kometa-Regular');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Kometa';
  @include fontSrc('Kometa-Italic');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Kometa';
  @include fontSrc('Kometa-Bold');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Kometa';
  @include fontSrc('Kometa-BoldItalic');
  font-weight: 700;
  font-style: italic;
}
