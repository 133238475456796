.hero {
  display: flex !important;
  flex-direction: row;
  position: relative;
  color: $white;

  &--frame {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      pointer-events: none;
      border: 50px solid $sand;

      @media (max-width: $md - 1px) {
        display: none;
      }
    }
  }

  &--nobg {
    color: $brown;
    background-color: $sand;

    .hero__bg {
      display: none;
    }
  }

  &__bg {
    background: black;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    pointer-events: none;
    @include bgCover;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($brown, 0.6);
    }
  }

  &__btns {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 40px 0;

    @media (min-width: $md) {
      padding: 40px 0 100px;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 100vh;
    padding: 135px 0;

    @media (min-width: $md) {
      padding: 195px 0;
    }

    .wrap > p {
      opacity: 0.6;
      margin-top: 1.5em;
    }
  }

  &--small {
    flex-wrap: wrap;

    .hero__text {
      min-height: 0;

      @media (max-width: $md - 1px) {
        padding-bottom: 45px;
      }
    }

    @media (max-width: $md - 1px) {
      .hero__btns {
        position: relative;
        margin-top: -45px;
        padding-bottom: 60px;
      }
    }
  }

  &__back {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .arrowbtn {
      margin-right: 20px;
      margin-left: -20px;
    }

    &:hover {
      .arrowbtn {
        &::before {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}
