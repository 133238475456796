.news {
  margin: 70px 0;

  @media (max-width: $md - 1px) {
    margin: 50px 0 25px;
  }

  &__list {
    @media (min-width: $md) {
      margin: 0 -8px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }

  &__article {
    width: calc(#{percentage(1/3)} - 16px);
    margin: 8px;

    @media (max-width: $md - 1px) {
      width: 100%;
      margin: 50px 0;
      display: block;
    }

    &--featured {
      @media (max-width: $md - 1px) {
        .news__photo {
          width: calc(100% + 35px);
        }
      }

      @media (min-width: $md) {
        width: calc(100% - 16px);
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
          font-size: 35px;
          line-height: 43px;
        }

        .news__text, .news__photo {
          width: calc(50% - 8px);
        }

        .news__text {
          padding: 0 5%;

          h4 {
            margin-bottom: 10px;
          }
        }

        .news__image {
          margin: 0;
        }
      }
    }

    &:hover {
      .news__text .arrowbtn::before {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &__image {
    padding-bottom: 100%;
    @include bgCover;
    margin-bottom: 30px;
  }

  &__text {
    h4 {
      margin-bottom: 0;
    }
  }

  &__date {
    margin-bottom: 20px;
    font-size: 11px;
    line-height: 16px;
    color: rgba($brown, 0.6);
    padding-left: 26px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 16px;
      width: 16px;
      @include bgContain;
      background-image: url(../img/icon_calendar.svg);
    }
  }
}
