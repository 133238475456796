.gallery {
  position: relative;
  overflow: hidden;

  &--about {
    .gallery__image {
      height: auto;
      padding-bottom: percentage(9/16);
      min-height: 360px;
    }
  }

  &--inner {
    @media (max-width: $md - 1px) {
      width: calc(100% + 70px);
      margin-left: -35px !important;
    }

    .gallery__thumbswrap {
      position: relative;

      @media (max-width: $md - 1px) {
        position: static;
      }
    }

    .gallery__image {
      height: auto;
      padding-bottom: percentage(9/16);
	    width: 100%;
    }

    @media (min-width: $md) {
      .gallery__nav .swiper-button {
        &-prev {
          &::before {
            background-image: url(../img/arrow_prev.svg);
          }
        }

        &-next {
          &::before {
            background-image: url(../img/arrow_next.svg);
          }
        }
      }
    }
  }

  &__image {
    height: 100vh;
    width: 100vw;
    @include bgCover;
  }

  &__thumbswrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px 0;

    @media (max-width: $md - 1px) {
      position: static;
      height: 0;
      padding: 0;


      .swiper-wrapper {
        pointer-events: none;
        opacity: 0;
      }

      .swiper-container {
        position: static;
      }
    }
  }

  &__thumbimage {
    padding-bottom: percentage(2/3);
    @include bgCover;
    cursor: pointer;
  }

  &__thumbs {
    overflow: visible;

    .swiper-slide {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s $easing;

      &.swiper-slide-visible {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__nav {
    .swiper-button {
      &-prev, &-next {
        right: auto;
        left: auto;
        top: 50%;
        transform: translateY(-50%);
        bottom: auto;
        position: absolute;
        padding: 15px 0;
        height: 40px;
        width: 60px;
        margin: 0;
        background: transparent;
        overflow: hidden;
        z-index: 5;

        &::before {
          content: '';
          top: 15px;
          position: absolute;
          transition: 0.3s $easing;
          width: 60px;
          height: 10px;
          background-size: 60px 10px;
          background-repeat: no-repeat;
        }

        &:hover {
          &::before {
            transform: translate3d(0, 0, 0);
          }
        }
      }

      &-prev {
        right: calc(100% + 30px);

        @media (max-width: $md - 1px) {
          right: auto;
          left: 0;
        }

        &::before {
          right: 0;
          background-image: url(../img/arrow_prev_l.svg);
          background-position: left center;
          transform: translate3d(20px, 0, 0);
        }
      }

      &-next {
        left: calc(100% + 30px);

        @media (max-width: $md - 1px) {
          left: auto;
          right: 0;
        }

        &::before {
          left: 0;
          background-image: url(../img/arrow_next_l.svg);
          background-position: right center;
          transform: translate3d(-20px, 0, 0);
        }
      }
    }
  }
}
