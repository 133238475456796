.playbutton {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-top: 30px;

  > p {
    margin-left: 12px;
  }

  &__button {
    width: 62px;
    height: 62px;
    display: inline-flex;
    align-items: center;
    position: relative;

    &::before, &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    &::before {
      opacity: 0.3;
      border: 1px solid currentColor;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      transition: 0.3s $easing;
    }

    &::after {
      border-left: 8px solid currentColor;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
    }
  }

  &:hover {
    .playbutton__button::before {
      opacity: 1;
    }
  }
}
