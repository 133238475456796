.form {
  &__input {
    position: relative;

    &:not(:first-child) {
      margin-top: 30px;

      @media (min-width: $md) {
        margin-top: 20px;
      }
    }

    input {
      width: 100%;
      height: 40px;
      border: 0;
      border-radius: 0;
      background: transparent;
      color: $brown;
      border-bottom: 1px solid rgba($brown, 0.15);

      @media (min-width: $md) {
        height: 45px;
      }
    }

    label, input {
      width: 100%;
      font-size: 13px;
      letter-spacing: 0.01em;
      font-family: $font;

      @media (min-width: $md) {
        font-size: 15px;
      }
    }

    label {
      position: absolute;
      top: 0;
      left: 0;
      line-height: 39px;
      color: rgba($brown, 0.6);
      transition: 0.2s $easing;

      @media (min-width: $md) {
        line-height: 44px;
      }

      a {
        color: $brown;

        &:hover {
          color: rgba($brown, 0.6);
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $brown;
      transform-origin: left center;
      transform: scaleX(0);
      transition: 0.3s $easing;
    }

    &--focus, &--full {
      &::before {
        transform: scaleX(1);
      }

      label {
        line-height: 17px;
        font-size: 11px;
        top: -10px;
      }
    }

    &--error {
      &::before {
        transform: scaleX(1);
        background-color: $red;
      }
    }

    &--checkbox {
      &::before {
        display: none;
      }

      &:not(:first-child) {
        margin-top: 20px;
      }

      input {
        display: none;

        &:checked + label::after {
          width: 18px;
          opacity: 1;
        }
      }

      label {
        position: relative;
        line-height: 18px;
        padding-left: 28px;
        font-size: 11px;
        top: 0;
        width: auto;
        display: inline-block;

        @media (min-width: $md) {
          font-size: 13px;
        }

        &::before, &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 18px;
          transition: 0.3s $easing;
        }

        &::before {
          width: 18px;
          border: 1px solid rgba($brown, 0.15);
        }

        &::after {
          background-image: url(../img/check.svg);
          background-size: 10px 7px;
          background-position: left 4px center;
          background-repeat: no-repeat;
          width: 0;
          opacity: 0;
        }
      }

      &.form__input--full label, & label:hover {
        &::before{
          border-color: $brown;
        }
      }

      &.form__input--error label::before{
        border-color: $red;
      }
    }
  }

  &__submit {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__error {
    padding-top: 20px;
    padding-left: 24px;
    line-height: 18px;
    font-size: 11px;
    color: $red;
    letter-spacing: 0.01em;
    opacity: 1;
    position: relative;
    display: none;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 20px;
      width: 18px;
      height: 18px;
      @include bgContain;
      background-image: url(../img/icon_error.svg);
    }

    &--show {
      display: block;
    }
  }

  &__success {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $green;
    color: $brown;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    user-select: none;
    cursor: default;
    z-index: 5;

    &::before {
      content: '';
      background-image: url(../img/check-big.svg);
      background-size: 22px 17px;
      background-position: right center;
      background-repeat: no-repeat;
      width: 22px;
      height: 17px;
      margin-right: 10px;
      pointer-events: none;
      position: relative;
      z-index: 1;
    }
  }
}
