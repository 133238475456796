h1, h2 {
  font-size: 35px;
  line-height: 43px;

  @media (min-width: $md) {
    font-size: 47px;
    line-height: 57px;
  }
}

h3 {
  font-size: 23px;
  line-height: 31px;

  @media (min-width: $md) {
    font-size: 35px;
    line-height: 43px;
  }
}

h4 {
  font-size: 19px;
  line-height: 31px;

  @media (min-width: $md) {
    font-size: 23px;
    line-height: 31px;
  }
}

h5 {
  font-weight: 700;
}

p, ul:not([class]) li, h5 {
  font-size: 13px;
  line-height: 20px;

  @media (min-width: $md) {
    font-size: 15px;
    line-height: 25px;
  }

  a {
    display: inline;
    opacity: 0.5;
    transition: 0.15s $easing;

    &:hover {
      opacity: 1;
    }
  }
}

ul:not([class]) li {
  padding-left: 20px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
  }
}

ul:not([class]) li::before {
  top: 12px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: currentColor;
}

ol {
  counter-reset: olist;

  > li {
    counter-increment: olist;

    &::before {
      top: 0;
      content: counter(olist)".";
      color: currentColor;
    }
  }
}

b, strong {
  font-weight: 700;
}

i, em {
  font-style: italic;
}

sup, sub {
  line-height: inherit;
  font-size: 0.8em;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  top: 0.25em;
}
