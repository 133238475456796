.header {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  color: $brown;

  &__flex {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 75px;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    background-color: $sand;
    height: 75px;
    width: 100%;
    transition: 0.3s $easing;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 73px;
    height: 2px;
    background-color: $brown;
    width: 100%;
    transform: scaleX(0);
    transform-origin: left center;
    transition: 1s $easing;
  }

  &--line {
    &::after {
      transform: scaleX(1);
    }
  }

  &__toggler {
    width: 42px;
    height: 42px;
    padding: 12px 6px;
    margin-top: 24px;
    cursor: pointer;
    position: relative;
    z-index: 3;

    @media (min-width: $md) {
      display: none;
    }

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 21px);
      left: calc(50% - 21px);
      width: 100%;
      height: 100%;
      border: 2px solid currentColor;
      border-radius: 50%;
      pointer-events: none;
      transform: scale(0.5);
      opacity: 0;
      transition: transform 0.7s $easing, opacity 0.7s $easing;
    }

    > div {
      width: 100%;
      height: 100%;
      position: relative;

      > div {
        position: absolute;
        background-color: currentColor;
        transition: width 0.3s $easing 0.4s, top 0.3s $easing 0.4s, right 0.3s $easing 0.4s, opacity 0.05s $easing 0.35s, transform 0.35s $easing;
        height: 2px;
        right: 0;

        &:nth-child(1) {
          top: 0;
          width: 24px;
        }

        &:nth-child(2) {
          top: 8px;
          width: 28px;
        }

        &:nth-child(3) {
          top: 16px;
          width: 20px;
        }
      }
    }

    &--open {
      &::before {
        transform: scale(1);
        opacity: 1;
        border-color: $brown;
      }

      > div > div {
        background-color: $brown;
        transition: width 0.3s $easing, top 0.3s $easing, right 0.3s $easing, opacity 0.05s $easing 0.3s, transform 0.35s $easing 0.35s;
        right: 6px;

        &:nth-child(1) {
          top: 8px;
          width: 18px;
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
          width: 18px;
        }

        &:nth-child(3) {
          top: 8px;
          width: 18px;
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__logo {
    margin-top: 15px;
    height: 90px;
    width: 68px;
    overflow: hidden;

    @media (min-width: $md) {
      margin-left: -6.5px;
      width: 127px;
      height: 60px;
      overflow: visible;
    }

    .svg--logo {
      height: 90px;
      width: 127px;
      display: block !important;
      opacity: 0;
      transition: opacity 0.15s $easing;

      .st0 {
        fill: $sand;
      }

      .st1 {
        fill: currentColor;
      }

      .st2 {
        fill: none;
        stroke: currentColor;
        stroke-width: 2px;
        stroke-miterlimit: 10;
      }
    }

    &.go, &.ie {
      .svg {
        opacity: 1;
      }
    }

    &.go {
      .svg {
        .st0 {
          transition: 0.3s $easing;
        }

        #kvad, #kvad2, #kvad3, #kvad4, #medis1, #medis3, #mini, #t {
          animation: offset 5s linear forwards;
        }

        #apacia1, #apacia3 {
          animation: offset 3s linear forwards;
        }

        #ilgas, #virsus {
          animation: offset 4s linear forwards;
        }

        #medis2 {
          animation: offset 1s linear forwards;
        }

        #kvad {
          stroke-dasharray: 500;
          stroke-dashoffset: 500;
        }

        #kvad2 {
          stroke-dasharray: 500;
          stroke-dashoffset: 500;
        }

        #kvad3 {
          stroke-dasharray: 500;
          stroke-dashoffset: 500;
        }

        #kvad4 {
          stroke-dasharray: 500;
          stroke-dashoffset: 500;
        }

        #ilgas {
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
        }

        #virsus {
          stroke-dasharray: 500;
          stroke-dashoffset: 500;
        }

        #medis1 {
          stroke-dasharray: 200;
          stroke-dashoffset: 200;
        }

        #medis2 {
          stroke-dasharray: 70;
          stroke-dashoffset: 70;
          animation-delay: 0.7s;
        }

        #medis3 {
          stroke-dasharray: 200;
          stroke-dashoffset: 200;
        }

        #apacia1 {
          stroke-dasharray: 100;
          stroke-dashoffset: 100;
          animation-delay: 1.7s;
        }

        #apacia3 {
          stroke-dasharray: 100;
          stroke-dashoffset: 100;
          animation-delay: 2s;
        }

        #mini {
          stroke-dasharray: 200;
          stroke-dashoffset: 200;
        }

        #t {
          stroke-dasharray: 400;
          stroke-dashoffset: 400;
        }

        #text {
          animation: fadein 1.5s;
        }
      }
    }
  }

  &:not(.header--scrolled) {
    &.header {
      &--transb, &--transw {
        &::before {
          background-color: transparent;
        }

        &::after {
          display: none;
        }

        .header__logo {
          .svg--logo .st0 {
            fill: transparent;
          }
        }
      }

      &--transw {
        color: $white;

        .menu {
          color: $white;
        }
      }
    }
  }
}
